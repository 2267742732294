import React from 'react'
import './styles/about.css'
import './styles/mediaqueries.css'


function About() {



    return (
        <section className="about" id="about">
            <div className="about-content">

                <h2 className="heading">Introducing Myself : <span>A Short Overview </span></h2>
                <div className="skills">
                    <p> I am a person of many interests and hobbies.
                        When I'm not busy coding or gaming, you can often find me enjoying the great outdoors.
                        Whether it's practicing archery, hiking, camping, or kayaking,
                        I love spending time in nature and enjoying the beauty of our world.</p>
                    <p>At the end of 2022, I began establishing my online presence to showcase my self-taught skills in web development and design.</p>
                    <p>Feel free to look around and reach out!</p>
                </div>

                <div className="about-img">
                    <img src={require('../assets/images/profile.png')} alt="" />
                </div>
                {/* <p className='dev-icons'><img src={require('../assets/images/js.png')} alt="" /><img src={require('../assets/images/html.png')} alt="" /><img src={require('../assets/images/css.png')} alt="" /><img src={require('../assets/images/react.png')} alt="" /></p> */}
            </div>
        </section >
    )
}

export default About